<template>
  <div>
    <DetailViewSuperAdmin />
  </div>
</template>
<script>
import DetailViewSuperAdmin from '@/components/DetailViewSuperAdmin.vue'

  export default {
    name: 'ProductDetail',
    components: {
      DetailViewSuperAdmin
    }
  }
</script>